import {createContext, useContext, useEffect, useRef, useState} from 'react';
import {useSessionStorage} from '../hooks/useSessionStorage';
import {useLocalStorage} from "../hooks/useLocalStorage";

const PageContext = createContext();
export const usePage = () => useContext(PageContext);

const PageProvider = ({children}) => {
    const [showing, setShowing] = useSessionStorage('showing', 'home');
    const [activeShopTab, setActiveShopTab] = useSessionStorage('activeShopTab', 'SEARCH'); // Value will be 'BROWSE' or 'SEARCH'
    const [selectedCartItem, setSelectedCartItem] = useSessionStorage('selectedCartItem', null);
    const [currentSlider, setCurrentSlider] = useSessionStorage('currentSlider', null);
    const [loading, setLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [zoomed, setZoomed] = useState(false);

    const [borderPreference, setBorderPreference] = useSessionStorage('borderPreference', 'TB');
    const showingRef = useRef();
    const [swiperProduct, setSwiperProduct] = useState(null);
    const [filterCleared, setFilterCleared] = useState(false);
    const [transitionStarted, setTransitionStarted] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState(null);

    const [country, setCountry] = useSessionStorage('country', '');
    const [floatPictures, setFloatPictures] = useSessionStorage('floatPictures', []);
    const [filteredFloatPictures, setFilteredFloatPictures] = useSessionStorage(
        'filteredFoatPictures',
        []
    );
    const [filterValue, setFilterValue] = useSessionStorage('filterValue', '');
    const [zoomedProduct, setZoomedProduct] = useState(null);
    const [zoomedProductId, setZoomedProductId] = useState(null);
    const [isMenubarOpen, setIsMenubarOpen] = useState(false);
    const [artTimes, setArtTimes] = useSessionStorage('artTimes', null);
    const [currentSlide, setCurrentSlide] = useSessionStorage('currentSlide', null);
    const [stopReload, setStopReload] = useSessionStorage('stopReload', false);
    const [previousArtId, setPreviousArtId] = useSessionStorage('previous_art_id', null);
    const [comboboxValue, setComboboxValue] = useSessionStorage('comboboxValue', null);
    const [allProductsclicked, setAllProductsclicked] = useSessionStorage('allProductsclicked', null);
    const [noReload, setNoReload] = useSessionStorage('noReload', false);
    const [showOnboarding, setShowOnboarding] = useLocalStorage('showonboardslidearrow', true);
    const [isAddToCartOpen, setIsAddToCartOpen] = useState(false);
    const isTouchDevice = 'ontouchstart' in document.documentElement || navigator?.msMaxTouchPoints;

    const handleClickMenubarOpen = () => {
        setIsMenubarOpen((prev) => !prev);
    };
    const getIdFromUrl = () => {
        const url = window.location.pathname.split('/')
        if (url.length > 2) {
            return url[2]
        }
        return ''
    }

    const reset = () => {
        setShowing('home');
        setActiveShopTab("SEARCH");
        setSelectedCartItem(null);
        setIsMenubarOpen(false);
        setFloatPictures([]);
        // setCountry('');
        setBorderPreference('TB');
        setSwiperProduct(null);
        setFilterCleared(false);
        setTransitionStarted(false);
        setFilteredFloatPictures([]);
        setFilterValue('');
        setCurrentSlider('');
        setLoading(false);
        setActiveMenuItem(null);
        setClientSecret('');
        setZoomedProductId(null);
        setZoomed(false);
        setArtTimes(null);
        setCurrentSlide(null);
        setStopReload(false);
        setPreviousArtId(null);
        setComboboxValue(null);
        setAllProductsclicked(null);
        setNoReload(false)
        setShowOnboarding(true);
        setIsAddToCartOpen(false);
        setZoomedProduct(null);
    }

    useEffect(() => {
        if (!isMenubarOpen) {
            setActiveMenuItem(null);
        }
    }, [isMenubarOpen]);

    const value = {
        showing,
        setShowing,
        activeShopTab,
        setActiveShopTab,
        selectedCartItem,
        setSelectedCartItem,
        handleClickMenubarOpen,
        setIsMenubarOpen,
        isMenubarOpen,
        floatPictures,
        setFloatPictures,
        country,
        setCountry,
        showingRef,
        setBorderPreference,
        borderPreference,
        swiperProduct,
        setSwiperProduct,
        setFilterCleared,
        filterCleared,
        transitionStarted,
        setTransitionStarted,
        filteredFloatPictures,
        setFilteredFloatPictures,
        filterValue,
        setFilterValue,
        currentSlider,
        setCurrentSlider,
        loading,
        setLoading,
        activeMenuItem,
        setActiveMenuItem,
        clientSecret,
        setClientSecret,
        zoomedProductId,
        setZoomedProductId,
        zoomed,
        setZoomed,
        idFromUrl: getIdFromUrl(),
        setArtTimes,
        artTimes,
        currentSlide,
        setCurrentSlide,
        stopReload,
        setStopReload,
        previousArtId,
        setPreviousArtId,
        comboboxValue,
        setComboboxValue,
        allProductsclicked,
        setAllProductsclicked,
        noReload,
        setNoReload,
        showOnboarding,
        setShowOnboarding,
        isAddToCartOpen,
        setIsAddToCartOpen,
        zoomedProduct,
        setZoomedProduct,
        isTouchDevice,
        reset
    };
    return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export default PageProvider;
