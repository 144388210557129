import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Providers from './contexts/Providers';
import AllRoutes from './routes';
import CanonicalLink from './components/snippets/canonical-link/CanonicalLink.View';
import HomeView from "./views/home";

const App = () => (
	<Providers>
		<BrowserRouter>
			<CanonicalLink />
			<HomeView />
		</BrowserRouter>
	</Providers>
);

export default App;
