import React, {useEffect, useState} from 'react';
import {History, Keyboard, Manipulation, Mousewheel, Navigation} from 'swiper/modules';

import {Swiper, SwiperSlide} from 'swiper/react';
import UseWindowDimensions from '../../../../components/snippets/use-window-dimensions';

import {useOrder} from '../../../../contexts/OrderContext';
import {usePage} from '../../../../contexts/PageContext';
import TouchDeviceOption from "./TouchDeviceOption";
import NoTouchOption from "./NoTouchOption";
import {useNavigate} from "react-router-dom";

const CartSlides = () => {
    const navigate = useNavigate();
    const [swiper, setSwiper] = useState();
    const {
        cartItems,
    } = useOrder();
    const {height} = UseWindowDimensions();

    const {
        showing,
        setTransitionStarted,
        currentSlider,
        isAddToCartOpen,
        setZoomedProductId,
        setZoomed,
        idFromUrl,
        isTouchDevice,
        setZoomedProduct
    } = usePage();

    const onInit = async (swiper) => {
        if (!swiper) return;
        setSwiper(swiper);

        if (idFromUrl && swiper?.slides) {
            const slide = swiper.slides.find(item => item.getAttribute('data-history') === idFromUrl);
            if (slide) {
                const index = swiper.slides.indexOf(slide);
                swiper.slideTo(index, 0);
            } else {
                swiper.slideTo(0, 0);
                navigate(`/products/${cartItems[0].art_id}`);
            }
        }

        swiper.update();
    }

    const onSlideChange = async (swiper) => {
        if (!swiper) return;
        swiper.update();
    }
    useEffect(() => {
        if (!swiper || !idFromUrl) return;
        if (idFromUrl && swiper?.slides) {
            const slide = swiper.slides.find(item => item.getAttribute('data-history') === idFromUrl);
            if (slide) {
                const index = swiper.slides.indexOf(slide);
                swiper.slideTo(index, 0);
            }
        }

        swiper.update();
    }, [swiper, idFromUrl])

    const sliderImages = cartItems || [];

    return (
        <div className='slide-wrapper'>
            <Swiper
                key={`${showing}`}
                modules={[
                    Navigation,
                    History,
                    Keyboard,
                    Manipulation,
                    Mousewheel,
                ]}
                history={{
                    key: 'product'
                }}
                onSlideNextTransitionStart={() => {
                    setTransitionStarted(Date.now());
                }}
                onBeforeSlideChangeStart={() => {
                    setZoomed(false);
                    setZoomedProductId(null);
                    setZoomedProduct(null);
                }}
                onInit={async (swiper) => {
                    await onInit(swiper)
                }}
                onSlideChange={async (swiper) => {
                    await onSlideChange(swiper)
                }}
                speed={400}
                autoHeight={true}
                grabCursor={true}
                simulateTouch={true}
                mousewheel={true}
                keyboard={true}
                touchStartPreventDefault={false}
            >
                {sliderImages.map((item) => (
                    <SwiperSlide
                        key={`products_${currentSlider}_${item.art_id}`}
                        data-history={item.art_id}
                        className={`d-flex flex-column align-items-center justify-content-center w-100 [data-history="${item.art_id}"]`}
                    >
                        <div className={`swiper-slide-img-container `}
                             style={{height: height}}
                             data-msg={`${item.art_name} by ${item.artist_name}`}
                        >
                            <div>
                                {isTouchDevice ? (
                                    <TouchDeviceOption item={item} setShowBorderSwitchId={window.innerWidth < 600}/>
                                ) : (
                                    <NoTouchOption item={item} setShowBorderSwitchId={window.innerWidth < 600}/>
                                )}
                            </div>
                        </div>

                        <p className={`item-name ${isAddToCartOpen ? 'item-name-hidden' : 'item-name-visible'}`}
                           style={{display: 'hidden'}}
                           data-msg={`${item.art_name} by ${item.artist_name}`}
                        >
                            {item.art_name} by {item.artist_name}
                        </p>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
export default CartSlides;
