import { useEffect } from 'react';

const CanonicalLink = () => {
	const { protocol, hostname, pathname, hash } = window.location;
	const getCanonicalUrl = (protocol, hostname, pathname, hash) => {
		return `${protocol}//${hostname}${pathname}${hash}`;
	};

	useEffect(() => {
		const canonicalUrl = getCanonicalUrl(protocol, hostname, pathname, hash);

		let linkElement = document.querySelector("link[rel='canonical']");
		if (linkElement) {
			// Update the existing canonical link element
			linkElement.setAttribute('href', canonicalUrl);
		} else {
			// Create a new canonical link element
			linkElement = document.createElement('link');
			linkElement.setAttribute('rel', 'canonical');
			linkElement.setAttribute('href', canonicalUrl);
			document.head.appendChild(linkElement);
		}
	}, [protocol, hostname, pathname, hash]);

	return null;
};

export default CanonicalLink;
