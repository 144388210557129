export const COUNTRY_DATA = {
	us: {
		name: 'USA',
		fullName: 'United States',
		host: 'us',
		code: 'us',
		currency_code: 'USD',
		currency: 'USD $',
		symbol: '$',
		siteUrl: process.env.REACT_APP_URL_US,
	},
	ca: {
		name: 'Canada',
		fullName: 'Canada',
		host: 'ca',
		code: 'ca',
		currency_code: 'CAD',
		currency: 'CAD $',
		symbol: '$',
		siteUrl: process.env.REACT_APP_URL_CA,
	},
	uk: {
		name: 'UK',
		fullName: 'United Kingdom',
		host: 'uk',
		code: 'uk',
		currency_code: 'GBP',
		currency: 'GBP £',
		symbol: '£',
		siteUrl: process.env.REACT_APP_URL_UK,
	},
	au: {
		name: 'Australia',
		fullName: 'Australia',
		host: 'au',
		code: 'au',
		currency_code: 'AUD',
		currency: 'AUD $',
		symbol: '$',
		siteUrl: process.env.REACT_APP_URL_AU,
	},
	// other: {
	// 	name: '',
	// 	host: 'us',
	// 	code: 'us',
	// },
};
