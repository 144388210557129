import {useState} from "react";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import CookiePolicyModal from "../../snippets/cookie-policy-modal";
import Button from "../../ui/button/Button";
import "./CookieCondition.View.scss";

const CookieCondition = () => {
    const [iscookieCondition, setIscookieCondition] = useLocalStorage(
        "cookie",
        true
    );
    const [cookiePolicyModalOpen, setCookiePolicyModalOpen] = useState(false);
    const toggleCookiePolicyModal = () =>
        setCookiePolicyModalOpen((prev) => !prev);

    return (
        <>
            {iscookieCondition && (
                <div className="cookie-condition">
                    <div className="d-flex justify-content-between w-100 pb-3">
                        <img src="/images/logo.png"
                             loading="lazy" alt=""/>
                        <button
                            type="button"
                            className="close-btn"
                            onClick={() => setIscookieCondition(false)}
                        >
                            ×
                        </button>
                    </div>
                    <p className="content-para pb-2 pt-2">
                        We and selected third parties use cookies or similar technologies
                        for technical purposes and, with your consent, for other purposes as
                        specified in the cookie policy.
                        <br/>
                        <br/>
                        You can consent to the use of such technologies by using the
                        “Accept” button, by scrolling this page, by interacting with any
                        link or button outside of this notice or by continuing to browse
                        otherwise. By closing this notice, you continue without accepting.
                    </p>
                    <div className="d-flex justify-content-between w-100 pb-0 bottom-btn-container">
                        <Button
                            type="button"
                            text="Learn more"
                            className="single-btn"
                            onClick={toggleCookiePolicyModal}
                        />
                        <Button
                            type="button"
                            text="Accept"
                            className="single-btn"
                            onClick={() => setIscookieCondition(false)}
                        />
                    </div>
                    <CookiePolicyModal
                        customHeightLocationCookie={true}
                        acceptbtn={true}
                        open={cookiePolicyModalOpen}
                        toggle={toggleCookiePolicyModal}
                    />
                </div>
            )}
        </>
    );
};

export default CookieCondition;
