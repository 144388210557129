import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React, {useContext, useEffect, useState} from 'react';
import {Accordion, AccordionContext, Card, useAccordionToggle} from 'react-bootstrap';
import {useOrder} from '../../../contexts/OrderContext';
import {usePage} from '../../../contexts/PageContext';
import {getCookie, retailAPI, formatPriceStatic} from '../../../utils/common';
import CheckoutTab from '../checkout-tab/CheckoutTab.View';
import RequisiteInformationTab from '../requisite-information-tab';
import NowShowingTab from '../now-showing-tab';
import OrderList from '../order-list/OrderList.View';
import './CollapseHeader.View.scss';

import './CollapseHeader.View.scss';

const SingleListItem = ({children, eventKey, callback}) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type='button'
            className={`single-list-button ${isCurrentEventKey ? 'active-list-button' : ''}`}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
};

const CollapseHeader = () => {
    const {totalCartItemsPrice, totalCartItems, cartItems, priceDetails} = useOrder();
    const {activeMenuItem, setActiveMenuItem, clientSecret, setClientSecret, country} =
        usePage();
    useEffect(() => {
        (async () => {
            if (cartItems?.length) {
                let mod = cartItems.map(
                    ({art_id, art_name, artist_name, quantity, itemSize: artsize}) => {
                        return {artsize, art_id, art_name, artist_name, quantity};
                    }
                );
                let totalPrice = cartItems.reduce((acc, curr) => {
                    acc += curr.price * curr.quantity;
                    return acc;
                }, 0);
                try {
                    setClientSecret('');
                    let d = await fetch(
                        'https://floatpic-staging-rcg7asssaa-uc.a.run.app/create-payment-intent/',
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            method: 'POST',
                            body: JSON.stringify({
                                amount: totalPrice,
                                active_country: country || 'us',
                                metadata: mod,
                            }),
                        }
                    );
                    let r = await d.json();
                    setClientSecret(r?.resp?.client_secret);
                } catch (err) {
                    console.log(err);
                }
            }
        })();
        if (!cartItems.length) {
            setClientSecret('');
        }
    }, [cartItems]);
    const stripePromise = loadStripe(
        'pk_test_51IdiDtFh7Dasx8MokymoBsEmIltaHNS1Rv1wfTzwJ8LB0ndtWHyuPccrv4r46xEd7ITa2HbEo9Qci6xZXMadIO7C00KVTZEEwl'
    );

    const headerTabItems = [
        {
            id: '1',
            tabItemName: 'Show',
            tabItemBody: <NowShowingTab/>,
        },
        {
            id: '2',
            tabItemName: 'Requisite Information',
            tabItemBody: <RequisiteInformationTab/>,
        },
        {
            id: '3',
            tabItemName: `Your Order`,
            tabItemBody: <OrderList/>,
        },
        // {
        //   id: '4',
        //   tabItemName: 'Checkout',
        //   tabItemBody: (
        //     <Elements stripe={stripePromise}>
        //       <CheckoutTab />
        //     </Elements>
        //   ),
        // },
    ];
    const [appearance, setAppearance] = useState({
        time: new Date().getTime(),
        data: {
            theme: 'stripe',
            labels: 'floating',
            rules: {
                '.Input:focus': {
                    boxShadow: 'none',
                    ouline: 0,
                },
            },
        },
    });
    if (stripePromise && clientSecret) {
        headerTabItems.push({
            id: '4',
            tabItemName: 'Checkout',
            tabItemBody: (
                <div key={appearance.time}>
                    {stripePromise && clientSecret && (
                        <Elements
                            stripe={stripePromise}
                            options={{clientSecret, appearance: appearance.data}}
                        >
                            <CheckoutTab/>
                        </Elements>
                    )}
                </div>
            ),
        });
    }
    return (
        <div className='collapse-header-container'>
            <Accordion
                activeKey={activeMenuItem}
                onSelect={(e) => {
                    if (Number(e) === 4) {
                        setAppearance({
                            time: new Date().getTime(),
                            data: {
                                theme: 'stripe',
                                labels: 'floating',
                                rules: {
                                    '.Input:focus': {
                                        boxShadow: 'none',
                                        ouline: 0,
                                    },
                                },
                            },
                        });
                    }
                    if (Number(e) === 3) {
                        const products_details = cartItems.map((art) => ({
                            product: {id: art.art_id},
                        }));
                        let post_data = {
                            eventType: 'shopping-cart-page-view',
                            eventTime: new Date().toISOString(),
                            userInfo: {
                                userAgent: navigator.userAgent,
                                ipAddress: '192.168.1.100',
                            },
                            visitorId: getCookie('_ga'),
                            productDetails: [products_details],
                            cartId: '0000',
                            attributes: {
                                global_website: {
                                    text: [window.location.host],
                                    indexable: true,
                                    searchable: true,
                                },
                            },
                        };
                        retailAPI(post_data);
                    }
                    setActiveMenuItem((prevKey) => (prevKey === e ? null : e));
                }}
            >
                {headerTabItems.map((item, index) => (
                    <Card key={item?.id}>
                        <Card.Header>
                            <SingleListItem eventKey={item.id}>
                                {item.tabItemName === 'Your Order' ? (
                                    <>
                                        {totalCartItems > 0 ? (
                                            <span>
												<span>{item.tabItemName} </span>
												<span className='total-item-price'>
													({totalCartItems}
                                                    {totalCartItems > 1 ? ' Items, ' : ' Item, '}
                                                    {formatPriceStatic(
                                                        totalCartItemsPrice,
                                                        priceDetails?.currency_code
                                                    )}
                                                    )
												</span>
											</span>
                                        ) : (
                                            <span>{item.tabItemName} </span>
                                        )}
                                    </>
                                ) : item.tabItemName === 'Show' ? (<span>{`Show`}</span>) : (
                                    item.tabItemName
                                )}
                            </SingleListItem>
                        </Card.Header>
                        <Accordion.Collapse eventKey={item.id}>
                            <Card.Body>{item.tabItemBody}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </div>
    );
};
export default CollapseHeader;
