import React, {useState} from "react";
import ModalCommon from "../../ui/modal";
import PartialCookiePolicy from "../partial-cookie-policy/PartialCookiePolicy.View";
import CompletePrivacyPolicy from "../complete-privacy-policy/CompletePrivacyPolicy.View";
import "./CookiePolicyModal.View.scss";

const CookiePolicyModal = (props) => {
    const {open, toggle, acceptbtn, customHeightLocationCookie} = props;
    const [isCompletePrivacyPolicy, setIsCompletePrivacyPolicy] = useState(false);
    const togglePrivacyPolicy = () => {
        setIsCompletePrivacyPolicy(!isCompletePrivacyPolicy);
    };

    return (
        <ModalCommon
            show={open}
            onHide={toggle}
            closemodal={toggle}
            contentClassName="privacy-policy-container"
            titleclass="modal-title-class"
            titleheader={<></>}
            headerclass="modal-header-class"
            footerclass="modal-footer-class"
            body={
                <>
                    {isCompletePrivacyPolicy ? (
                        <CompletePrivacyPolicy
                            togglePrivacyPolicy={togglePrivacyPolicy}
                            acceptbtn={acceptbtn}
                            acceptCloseBtn={toggle}
                            customHeightLocationCookie={customHeightLocationCookie}
                        />
                    ) : (
                        <PartialCookiePolicy
                            togglePrivacyPolicy={togglePrivacyPolicy}
                            acceptbtn={acceptbtn}
                            acceptCloseBtn={toggle}
                            customHeightLocationCookie={customHeightLocationCookie}
                        />
                    )}
                </>
            }
        />
    );
};
export default CookiePolicyModal;
