import React from "react";
import Modal from "react-bootstrap/Modal";
import "./Modal.View.scss";

const ModalCommon = (props) => {
    const {
        titleheader,
        body,
        // actionButtons,
        size = "lg",
        titleclass,
        closemodal,
        headerclass,
        // footerclass,
    } = props;
    return (
        <Modal
            {...props}
            size={size}
            aria-labelledby="example-custom-modal-styling-title"
            centered
        >
            <Modal.Header className={headerclass}>
                <Modal.Title id="contained-modal-title-vcenter" className={titleclass}>
                    {titleheader}
                </Modal.Title>
                <div className="close-button-container">
                    <img
                        src="/images/close.png"
                        alt="close"
                        onClick={() => closemodal(false)}
                        className="close-button"
                        loading="lazy"
                    />
                </div>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            {/* <Modal.Footer className={footerclass}>{actionButtons}</Modal.Footer> */}
        </Modal>
    );
};
export default ModalCommon;
