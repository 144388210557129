import React, { useState } from "react";
import ModalCommon from "../../ui/modal";
import PartialPrivacyPolicy from "../partial-privacy-policy/PartialPrivacyPolicy.View";
import CompletePrivacyPolicy from "../complete-privacy-policy/CompletePrivacyPolicy.View";
import "./PrivacyPolicyModal.View.scss";

const PrivacyPolicyModal = (props) => {
  const { open, toggle } = props;
  const [isCompletePrivacyPolicy, setIsCompletePrivacyPolicy] = useState(false);
  const togglePrivacyPolicy = () => {
    setIsCompletePrivacyPolicy(!isCompletePrivacyPolicy);
  };

  return (
    <ModalCommon
      show={open}
      onHide={toggle}
      closemodal={toggle}
      contentClassName="privacy-policy-container"
      titleclass="modal-title-class"
      titleheader={<></>}
      headerclass="modal-header-class"
      footerclass="modal-footer-class"
      body={
        <>
          {isCompletePrivacyPolicy ? (
            <CompletePrivacyPolicy togglePrivacyPolicy={togglePrivacyPolicy} />
          ) : (
            <PartialPrivacyPolicy togglePrivacyPolicy={togglePrivacyPolicy} />
          )}
        </>
      }
    />
  );
};
export default PrivacyPolicyModal;
